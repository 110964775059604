/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import GeneralStore from "../../stores/GeneralStore";
import styles from "./Products.module.scss";
import ShopProductItem from "../../components/ShopProductItem";
import Icon from "@material-ui/core/Icon";

const Wishlist = () => {
  const generalStore = useContext(GeneralStore);
  const [displayFormat, setDisplayFormat] = useState("dots");
  const [productsList, setProductsList] = useState([]);

  useEffect(() => {
    generalStore.setShowWhiteNavbar(false);
    setProductsList(generalStore.wishlist);
  }, []);

  return (
    <div className={styles.shopWrapper}>
      <section className={styles.wishlistTopBanner}>
        <h1 className="section-title">Wishlist</h1>
      </section>

      <section className={styles.productsSection}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="row mb-4">
                <div className="col-12 col-sm-4">
                  <div className={styles.displayingFormatDiv}>
                    <div
                      className={cx(styles.div, displayFormat === "dots" && styles.active)}
                      onClick={() => {
                        setDisplayFormat("dots");
                      }}
                    >
                      <Icon>apps</Icon>
                    </div>
                    <div
                      className={cx(styles.div, displayFormat === "list" && styles.active)}
                      onClick={() => {
                        setDisplayFormat("list");
                      }}
                    >
                      <Icon>format_list_bulleted</Icon>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-8">
                  <p className={styles.showingInfo}>Showing {productsList.length || "0"} product(s)</p>
                </div>
              </div>

              <div className="row">
                {productsList.map((product) => (
                  <div className={displayFormat === "dots" ? "col-12 col-lg-4" : "col-12"} key={product.id}>
                    <ShopProductItem
                      product={product}
                      displayFormat={displayFormat}
                      onAddToWishlist={(product) => {
                        let wishArr = [...generalStore.wishlist];

                        wishArr = wishArr.filter((el) => el.id !== product.id);

                        generalStore.setWishlist(wishArr);
                        setProductsList(wishArr);
                      }}
                    />
                  </div>
                ))}

                {productsList.length === 0 && (
                  <h1 className="text-center section-title w-100">You don't have any product in wishlist</h1>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Wishlist;
