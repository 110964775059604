import React, { useContext, useEffect, useState } from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import { reaction } from "mobx";
import styles from "./Header.module.scss";
import logo from "../assets/img/logo.png";
import logoWhite from "../assets/img/logo-white.png";
import Icon from "@material-ui/core/Icon";
import GeneralStore from "../stores/GeneralStore";
import SearchBox from "./SearchBox";

const Header = () => {
  const generalStore = useContext(GeneralStore);
  const [showWhite, setShowWhite] = useState(false);

  useEffect(() => {
    // For getting new requests when created new one
    reaction(
      () => generalStore.showWhiteNavbar,
      () => {
        setShowWhite(generalStore.showWhiteNavbar);
      }
    );
  });

  return (
    <header className={styles.header}>
      <div className="container-fluid">
        <div className="row">
          <div className={cx("col-12 col-md-3 d-flex justify-content-start", styles.colMobile)}>
            <NavLink exact={true} to="/">
              <div className={styles.logoDiv}>
                <img src={showWhite ? logoWhite : logo} alt="keybudz logo" />
              </div>
            </NavLink>
          </div>
          <div className={cx("col-12 col-md-6 d-flex justify-content-center", styles.colMobile)}>
            <nav className={cx(styles.mainNav, showWhite && styles.white)}>
              <ul>
                <li>
                  <NavLink exact={true} to="/products" activeClassName="active">
                    Products
                  </NavLink>

                  <ul className={styles.subLinks}>
                    <li>
                      <NavLink exact={true} to="/products#AirPods" activeClassName="active">
                        AirPods
                      </NavLink>
                    </li>

                    <li>
                      <NavLink exact={true} to="/products#AirPods Pro" activeClassName="active">
                        AirPods Pro
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink exact={true} to="/about" activeClassName="active">
                    About
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink exact={true} to="/support" activeClassName="active">
                    Support
                  </NavLink>
                </li> */}
              </ul>
            </nav>
          </div>
          <div className={cx("col-12 col-md-3 d-flex justify-content-end", styles.colMobile)}>
            <div className={styles.searchDiv}>
              <SearchBox showWhite={showWhite} />
            </div>
            <div className={cx(styles.wishlistDiv, showWhite && styles.white)}>
              <NavLink exact={true} to="/wishlist" activeClassName="active">
                <Icon>favorite_border</Icon>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
